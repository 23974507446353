@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  max-height: 100%;
  max-width: 100%;
}

#app-wrapper.dark .tox.tox-tinymce,
#app-wrapper.dark .tox-editor-header,
#app-wrapper.dark .tox-menubar,
#app-wrapper.dark .tox-statusbar,
#app-wrapper.dark .tox-toolbar__primary {
  background: #141414 !important;
}

#app-wrapper.dark .tox-statusbar,
#app-wrapper.dark .tox-statusbar__path-item,
#app-wrapper.dark .tox-statusbar__wordcount {
  color: #fff !important;
}

#app-wrapper.dark .tox-statusbar {
  border-color: #424242 !important;
}

#app-wrapper.dark .tox.tox-tinymce,
#app-wrapper.dark .tox-toolbar-overlord {
  border: 1px solid #424242 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

#app-wrapper.dark .tox-toolbar-overlord {
  border-left: 0 !important;
  border-right: 0 !important;
}

.tox .tox-edit-area::before {
  border-color: transparent !important;
}

.tox-mbtn.tox-mbtn--select,
.tox-mbtn.tox-mbtn--select span,
.tox-tbtn,
.tox-tbtn__select-label {
  cursor: pointer !important;
}

.tox-menubar,
.tox-toolbar__group {
  gap: 4px !important;
}

#app-wrapper.dark .tox-mbtn.tox-mbtn--select,
#app-wrapper.dark .tox-tbtn {
  background: #141414 !important;
  color: white;
}

#app-wrapper.dark .tox .tox-tbtn svg {
  fill: white !important;
}
